
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { Options, Vue } from "vue-class-component";
import { cargoTypeData } from "../../modules/cargo-module";
import { gtmCargoData } from "../../modules/gtm";
import CargoTypeCard from "@/app/ui/views/cargo/booking-cargo/components/cargo-type-card.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";

@Options({
  components: {
    CargoTypeCard
  },
  emits: ["onChoose"]
})
export default class ListCargoType extends Vue {
  get form() {
    return CargoController.form;
  }

  selectedOption = "";
  planeStatus = "";

  isOptionActive(option: string, status?: string) {
    return status
      ? status === this.planeStatus && option === this.selectedOption
      : option === this.selectedOption;
  }

  // origin city
  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }
  get destinationCity(): OptionsClass {
    return CargoController.destinationCity;
  }

  // search cargo
  cargoType(type: string) {
    return cargoTypeData(type);
  }
  isSearch = false;
  get isLoading() {
    return CargoController.isLoading;
  }
  get cargoOptions() {
    return CargoController.cargoOptions.data.map(item => item.transportType);
  }
  get cargoAvailable() {
    return CargoController.isCargoAvailable;
  }

  // on choose cargo type
  get isNGenValid() {
    return !!getTokenNGen();
  }
  setDefaultDataToAirportPopup() {
    CargoController.setAirportsPopupModel(
      JSON.parse(JSON.stringify(this.form.airports))
    );
  }
  get showPlaneSchedule() {
    return CargoController.isShowPlaneSchedule;
  }
  set showPlaneSchedule(value: boolean) {
    CargoController.setShowPlaneSchedule(value);
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }
  cancelPopupAirport() {
    this.setDefaultDataToAirportPopup();
    this.showSelectAirport = false;
  }
  async onResetPopup() {
    if (!this.showPlaneSchedule && (this.flowBooking.includes("manual") || this.flowBooking === "rebooking" && !CargoController.cargoDetailData.isFromRtc)) CargoController.resetModalPlaneAuto();
  }
  get showSelectAirport() {
    return CargoController.showSelectAirport;
  }
  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }
  onShowBookingForm(val: boolean) {
    CargoController.setShowBookingForm(val);
  }

  get totalSttPieces() {
    return CargoController.totalSttPieces;
  }

  showPopupMaxKoliPlane(type: string, status: string) {
    if (type === "plane" && this.totalSttPieces > 15) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: this.$t("Maksimal Koli Pesawat adalah 15 koli"),
          image: "max-koli-plane",
          message:
            this.$t("Mohon ubah jumlah koli di dalam kargo atau pilih transportasi kargo lainnya"),
          textCancel: this.$t("Transportasi Lain"),
          textSuccess: this.$t("Ubah Koli"),
          onClose: () => {
            MainAppController.closeMessageModal();
          },
          onSubmit: () => {
            MainAppController.closeMessageModal();
            CargoController.setShowModalCargoType(false);
          }
        })
      );
      return;
    }
    this.planeStatus = status;
    this.onBookingCargo(type, status);
  }
  async onBookingCargo(type: string, status: string) {
    // cargo plane auth
    if (type === "plane") {
      await NGenController.onLoginNGen();
    }
    this.selectedOption = type;
    this.$emit("onChoose", type, status);

    // cargo plane auto
    if (type === "plane" && status === "auto") {
      const gtmPlaneAuto = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true
      }).type;

      // gtm cargo auto
      dataLayer(gtmPlaneAuto.eventName, gtmPlaneAuto.property, []);
      this.cancelPopupAirport();
      this.onResetPopup();
    } else {
      // gtm cargo manual
      if (type !== "ship") {
        const gtmCargoType = gtmCargoData({ type: this.form.cargoType })?.type;
        dataLayer(gtmCargoType.eventName, gtmCargoType.property, []);
      }

      // go to form booking
      this.onShowBookingForm(type === "plane" ? this.isNGenValid : true);

      // gtm truck/train
      if (this.form.cargoType === "truck" || this.form.cargoType === "train") {
        const gtmDetailView = gtmCargoData({ type: this.form.cargoType })
          .detailView;

        dataLayer(gtmDetailView.eventName, gtmDetailView.property, []);
      }
    }
  }
}
